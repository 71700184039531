import styled from "styled-components"
import media from "styled-media-query"

export const BackgroundColor = styled.div`
  background: var(--main-color);
`

export const Container = styled.section`
  width: 100%;
  height: 100vh;

  background: url("${props => props.background}");
  background-size: cover;
  /* background-attachment: fixed; */
  display: flex;
  align-items: center;
`
export const TextWrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;

  color: #fff;

  h2 {
    font-size: clamp(2rem, 5vw, 8rem);
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: clamp(1rem, 2vw, 8rem);
  }

  ${media.lessThan("medium")`
        text-align: center;
    `}
`

export const ScrollIcon = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50vw;
  transform: translate(-50%);
  width: 30px;
`
