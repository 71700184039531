import React from "react"
import * as S from "./styled"

import ScrollIcon from "../../images/slideimg.gif"
import background from "../../images/back1.jpg"

const WelcomeSection = () => {
  return (
    <S.BackgroundColor>
      <S.Container background={background} className="image-wrapper">
        <S.TextWrapper className="home-text-wrapper">
          <h2>Alugue seu imóvel em Peruíbe</h2>
          <p>
            Aluguel fácil, rápido, sem burocracia e com o apoio de
            especialistas!
          </p>
        </S.TextWrapper>
        <S.ScrollIcon>
          <img src={ScrollIcon} alt="Deslize para baixo" />
        </S.ScrollIcon>
      </S.Container>
    </S.BackgroundColor>
  )
}

export default WelcomeSection
