import styled from "styled-components"

export const CardsContainer = styled.section`
width: 90%;
  max-width: 1200px;
  margin 50px auto 0 auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Card = styled.div`
    width: 30%;
    min-width: 200px;
    max-width: 250px;
    min-height: 300px;
    margin: 20px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items flex-start;

    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    transition: all 200ms ease-in-out;

    &:hover {
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
        transform: scale(1.02)
    }

    h2 {
        margin: 20px 0 10px 0;
        color: var(--main-color);
        font-weight: 700;
        font-size: 1.5em;
    }
`

export const ImageWrapper = styled.div`
  height: 100px;
  width: 100px;
  margin: 0 auto;
  img {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
`
