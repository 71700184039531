import React from "react"
import * as S from "./styled"

import content from "./content"

const Card = ({ img, title, description }) => {
  return (
    <S.Card>
      <S.ImageWrapper>
        <img src={img} alt="Foto do imóvel" />
      </S.ImageWrapper>
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </S.Card>
  )
}

const InfoCards = () => {
  return (
    <S.CardsContainer>
      {content.map((item, key) => (
        <Card
          key={key}
          img={item.image}
          title={item.title}
          description={item.description}
        />
      ))}
    </S.CardsContainer>
  )
}

export default InfoCards
