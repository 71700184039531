import React from "react"
import * as S from "./styled"

import { Close } from "@styled-icons/evaicons-solid/Close"

const WhatsappModal = ({ setShowWhatsappModal }) => {
  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    let url = prepareURL(form.tipo.value, form.valor.value, form.pessoas.value)
    window.location.href = url
  }

  function prepareMensagem(mensagem) {
    mensagem = formatText(mensagem)
    mensagem = window.encodeURIComponent(mensagem)
    return mensagem
  }

  function prepareURL(tipo, valor, qtdPessoas) {
    /* let link = "https://web.whatsapp.com/send?"; */
    let link = "https://api.whatsapp.com/send?"

    let mensagem = `*Ola, especialista da Paulinho Imóveis Peruíbe!*.\nPor gentileza me ajude encontrar uma *imóvel* para locação *definitiva*.\n*Cheguei através do site Alugar Imóveis em Peruíbe*\n\n`

    if (tipo !== "") {
      mensagem += `Estou procurando *${tipo}*\n`
    } else {
      mensagem += "Estou procurando alguns imóveis.\n"
    }

    if (valor !== "") {
      mensagem += `Com um valor próximo de *${valor}*\n`
    } else {
      mensagem += "Não tenho uma média de preços\n"
    }

    if (valor !== "" && qtdPessoas !== "") {
      mensagem += "E "
    } else if (valor !== "" && qtdPessoas === "") {
      mensagem += "Mas "
    } else if (valor === "" && qtdPessoas === "") {
      mensagem += "E também "
    } else if (valor === "" && qtdPessoas !== "") {
      mensagem += "Mas preciso de "
    }

    if (qtdPessoas !== "") {
      mensagem += `capacidade de habitação para *${qtdPessoas}*`
    } else {
      mensagem += "não tenho uma capacidade de habitação definida\n"
    }

    return (link += "phone=5513997883549&text=" + prepareMensagem(mensagem))
  }

  function formatText(mensagem) {
    mensagem = mensagem.replace(/<b>/g, "*").replace(/<\/b>/g, "*")
    mensagem = mensagem.replace(/<i>/g, "_").replace(/<\/i>/g, "_")
    mensagem = mensagem.replace(/<strike>/g, "~").replace(/<\/strike>/g, "~")
    mensagem = mensagem.replace(/<tt>/g, "```").replace(/<\/tt>/g, "```")

    return mensagem
  }

  return (
    <S.Container>
      <form onSubmit={handleSubmit}>
        <S.Modal>
          <S.CloseButton onClick={() => setShowWhatsappModal(false)}>
            <Close size="30" color="#fff" />
          </S.CloseButton>
          <h2>Vamos adiantar algumas perguntas:</h2>
          <S.InputBox>
            <p>O que você está procurando?</p>
            <S.Options>
              <div>
                <input type="radio" id="casa" name="tipo" value="casas" />
                <label for="casa">casa</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="apartamentos"
                  name="tipo"
                  value="apartamento"
                />
                <label for="apartamentos">apartamento</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="ambos"
                  name="tipo"
                  value="casas e apartamentos"
                />
                <label for="ambos">ambos</label>
              </div>
            </S.Options>
          </S.InputBox>

          <S.InputBox>
            <p>Qual o valor máximo?</p>
            <S.Options>
              <div>
                <input type="radio" id="900" name="valor" value="R$ 900,00" />
                <label for="900">R$ 900,00</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="1500"
                  name="valor"
                  value="R$ 1.500,00"
                />
                <label for="1500">R$ 1.500,00</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="2000"
                  name="valor"
                  value="R$ 2.000,00"
                />
                <label for="2000">R$ 2.000,00</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="6000"
                  name="valor"
                  value="R$ 6.000,00"
                />
                <label for="6000">R$ 6.000,00</label>
              </div>
            </S.Options>
          </S.InputBox>

          <S.InputBox>
            <p>Quantas pessoas vão morar no imóvel?</p>
            <S.Options>
              <div>
                <input
                  type="radio"
                  id="qtd-pessoas2"
                  name="pessoas"
                  value="até 2 pessoas"
                />
                <label for="qtd-pessoas2">até 2</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="qtd-pessoas3"
                  name="pessoas"
                  value="até 3 pessoas"
                />
                <label for="qtd-pessoas3">até 3</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="qtd-pessoas4"
                  name="pessoas"
                  value="até 4 pessoas"
                />
                <label for="qtd-pessoas4">até 4</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="qtd-pessoas5"
                  name="pessoas"
                  value="mais que 4 pessoas"
                />
                <label for="qtd-pessoas5">mais que 4</label>
              </div>
            </S.Options>
          </S.InputBox>

          <S.InputBox>
            <button type="submit">FALAR COM UM ESPECIALISTA AGORA</button>
          </S.InputBox>
        </S.Modal>
      </form>
    </S.Container>
  )
}

export default WhatsappModal
