import styled, { keyframes } from "styled-components"
import media from "styled-media-query"

const slideUpFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(500px);
  }to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fade = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;

  animation ${fade} 500ms ease-in-out;
`
export const Modal = styled.div`
  background-color: #fff;
  padding: 40px;
  color: var(--main-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  animation ${slideUpFade} 500ms ease-in-out;

    ${media.lessThan("medium")`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    padding: 5vw;
  `}
    h2 {
    font-size: 2em;
  }
`
export const CloseButton = styled.button`
  background: var(--main-color);
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 20px;
  right: -40px;
  cursor: pointer;

  ${media.lessThan("medium")`
    top: 10px;
    right: 10px;
  `}
`

export const InputBox = styled.div`
  margin-top: 30px;

  p {
    font-size: 1.3em;
    margin-bottom: 20px;
    font-weight: bold;
  }

  button {
    width: 100%;
    padding: 30px;
    background-color: var(--main-color);
    color: #fff;
    border: none;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    font-size: 1.2em;
    font-weight: bold;
    box-shadow: 5px 5px 15px var(--main-color);
    animation: pulseButton 2s infinite ease-in-out;
  }

  button:hover {
    transform: scale(1.05);
    box-shadow: 5px 5px 30px var(--main-color);
    animation: none;
  }
`
export const Options = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div > input {
    display: none;
  }

  div > label {
    color: var(--main-color);
    display: block;
    padding: 5px 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 0.8em;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    transition: all 200ms ease-in-out;
    cursor: pointer;
    user-select: none;
  }

  div > label:hover {
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
  }

  div > input:checked ~ label {
    box-shadow: inset 5px 5px 20px rgba(0, 0, 0, 0.25);
  }
`
