import React from "react"
import * as S from "./styled"
import { Link } from "gatsby"

import laptopImage from "../../images/direita.png"
import smartphoneImage from "../../images/esquerda.png"

import siteSvg from "../../images/site.svg"
import { Whatsapp } from "@styled-icons/remix-line/Whatsapp"

import AniLink from "gatsby-plugin-transition-link/AniLink"

const FullPageLinksSection = ({ setShowWhatsappModal }) => {
  return (
    <>
      <S.Title>Entre em contato</S.Title>
      <S.Container>
        <S.LinkSection>
          <button onClick={() => setShowWhatsappModal(true)}>
            <S.Background background={smartphoneImage}>
              <Whatsapp color="#fff" />
              <p>Pelo WhatsApp</p>
            </S.Background>
          </button>
        </S.LinkSection>
        <S.LinkSection>
          <Link to="/pesquisa/">
            <S.Background background={laptopImage}>
              <img src={siteSvg} alt="Icone de navegação" />
              <p>Pelo nosso site</p>
            </S.Background>
          </Link>
        </S.LinkSection>
      </S.Container>
    </>
  )
}

export default FullPageLinksSection
