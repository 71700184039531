import React from "react"
import * as S from "./styled"

const VideoSection = () => {
  return (
    <S.Container>
      <S.ContentWrapper>
        <S.Text>
          <p>Quer alugar um imóvel em Peruíbe com rapidez e facilidade?</p>
          <h2>Veja como funciona</h2>
        </S.Text>
        <S.VideoWrapper>
          <S.IframeWrapper>
            <iframe
              src="https://www.youtube.com/embed/UY-fZcSpTFY"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </S.IframeWrapper>
        </S.VideoWrapper>
      </S.ContentWrapper>
    </S.Container>
  )
}

export default VideoSection
