import React, { useState } from "react"
import * as S from "./styled"

import ItemsCarousel from "react-items-carousel"

//* Icons
import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline"
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline"

import content from "./content"

const Chevron = ({ direction }) => {
  const Icon =
    direction === "left" ? ArrowIosBackOutline : ArrowIosForwardOutline
  return <Icon size="40" color="var(--main-color)" />
}

const InformationSlide = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const chevronWidth = 40

  return (
    <S.Container>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={1}
        gutter={20}
        leftChevron={<Chevron direction="left" />}
        rightChevron={<Chevron direction="right" />}
        outsideChevron={false}
        chevronWidth={chevronWidth}
        infiniteLoop={true}
      >
        {content.map((item, key) => (
          <S.SliderItem key={key}>
            <div>
              <h2>{item.title}</h2>
              <p>{item.text}</p>
            </div>
          </S.SliderItem>
        ))}
      </ItemsCarousel>
    </S.Container>
  )
}

export default InformationSlide
