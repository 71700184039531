import styled from "styled-components"

export const Container = styled.section`
  width: 100%;
`

export const SliderItem = styled.div`
  min-height: 300px;

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    max-width: 60%;
  }

  h2 {
    color: var(--main-color);
    font-weight: 700;
    font-size: clamp(1rem, 5vw, 2.6rem);
    margin-bottom: 10px;
  }

  p {
    font-size: 1.5em;
    font-size: clamp(1rem, 3vw, 1.6rem);
    max-width: 900px;
  }
`
