import styled from "styled-components"
import media from "styled-media-query"

export const Title = styled.h2`
  font-size: 1.3em;
  color: var(--main-color);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
`

export const Container = styled.section`
  width: 100%;
  height: 90vh;

  display: flex;

  ${media.lessThan("medium")`
  height: auto;
  padding-bottom: 50px;
  `}
`

export const LinkSection = styled.div`
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;

  a {
    width: 100%;
    height: 100%;
  }

  button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0;
    border: none;
  }

  ${media.lessThan("medium")`
    width: 40vw;
    height: 40vw;
    margin: 0 auto;
  `}
`
export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  lefth: 0;

  background: url("${props => props.background}");
  background-color: var(--main-color);
  background-size: cover;
  background-position: center;

  transition: all 200ms ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }

  img,
  svg {
    width: 20%;
  }

  p {
    font-size: 3em;
    font-weight: 700;
    color: #fff;
  }

  ${media.lessThan("medium")`
  img, svg {
  }

  p {
    font-size: 1em;
  }
  `}
`
