import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.section`
  width: 100%;
`

export const ContentWrapper = styled.div`
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Text = styled.div`
  text-align: center;
  color: var(--main-color);

  p {
    margin: 20px 0;
    font-size: clamp(1rem, 2vw, 5rem);
  }

  h2 {
    text-transform: uppercase;
    font-size: clamp(1.5rem, 3vw, 5rem);
    margin-bottom: 20px;
    font-weight: 700;
  }
`

export const VideoWrapper = styled.div`
  width: 50vw;
  margin-bottom: 50px;

  ${media.lessThan("medium")`
    width: 70vw;
  `}

  ${media.lessThan("small")`
    width: 90vw;
  `}
`

export const IframeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`
