const content = [
  {
    title: "História",
    text:
      "Há 30 anos atuando no mercado imobiliário de Peruíbe, temos muita experiência e histórias de sucesso. Queremos você faça parte dessa história também.",
  },
  {
    title: "Equipe",
    text:
      "Temos uma equipe completa e experiente. Contamos com apoio administrativo, financeiro, divulgação, captação e, em especial, nossos consultores que estão à inteira disposição dos nossos clientes para ajudá-los no que for necessário.",
  },
  {
    title: "Apoio",
    text:
      "Disponibilizamos uma carteira de imóveis extensa, onde pode encontrar o seu imóvel de forma rápida e segura. Porém, caso queira algo personalizado ou não tenha encontrado o que procura em nosso site, nossos consultores buscarão o que deseja dentro dos requisitos indicados.",
  },
]

export default content
