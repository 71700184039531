import comodidade from "../../images/cards/comodidade.svg"
import facilidade from "../../images/cards/facilidade.svg"
import seguranca from "../../images/cards/seguranca.svg"

const content = [
  {
    image: comodidade,
    title: "Comodidade",
    description:
      "Seu imóvel em apenas alguns cliques. A facilidade de encontrar seu imóvel sem sair de casa. Entre em contato pelo Whatsapp ou consulte nossos imóveis. Nossos especialistas irão te ajudar em todo o processo.",
  },
  {
    image: facilidade,
    title: "Facilidade",
    description:
      "Faça tudo sem sair de casa. Agende sua visita on line e nosso corretor irá te encontrar no imóvel no dia e horário agendado para  tirar demais dúvidas que possam surgir.",
  },
  {
    image: seguranca,
    title: "Segurança",
    description:
      "Prezamos por toda a segurança do processo, desde o início até a assinatura do contrato que também pode ser feita de forma digital por uma plataforma especializada.",
  },
]

export default content
