import React, { useState } from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Header from "../components/Header"
import WelcomeSection from "../components/WelcomeSection"
import VideoSection from "../components/VideoSection"
import FullPageLinksSection from "../components/FullPageLinksSection"
import InformationSlide from "../components/InformationSlide"
import InfoCards from "../components/InfoCards"
import WhatsappModal from "../components/WhatsappModal"

const IndexPage = () => {
  const [showWhatsappModal, setShowWhatsappModal] = useState(false)

  return (
    <Layout>
      <SEO title="Paulinho imóveis"/>
      <Header />
      <WelcomeSection />
      <VideoSection />
      <FullPageLinksSection setShowWhatsappModal={setShowWhatsappModal} />
      <InfoCards />
      <InformationSlide />
      <FullPageLinksSection setShowWhatsappModal={setShowWhatsappModal} />
      {showWhatsappModal && (
        <WhatsappModal setShowWhatsappModal={setShowWhatsappModal} />
      )}
    </Layout>
  )
}

export default IndexPage
